import { CSSProperties, Fragment, ReactNode, useMemo } from 'react'
import { FreeMode, Autoplay, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'

import ButtonCircle from '../base-button/ButtonCircle'

import {
  DEFAULT_NEXT_CLS,
  DEFAULT_PREV_CLS,
  PAGINATION_CLS,
  PAGINATION_DYNAMIC_CLS,
} from '@/constants/swiper'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/free-mode'

import './index.scss'
import './dynamic-pagination.scss'

type SwiperSlidesProps = {
  items: (ReactNode | null | undefined)[]
  wrapperCls?: string
  style?: CSSProperties
  slidesPerView?: number
  pagination?: boolean
  paginationStyle?: CSSProperties
  paginationPosition?: 'inner' | 'outer'
  btnControlSize?: 'sm' | 'md' | 'lg' | 'mg'
  dynamicMainBullets?: number
  showNavigationButtons?: boolean
  isLoop?: boolean
  autoplay?: boolean
  delayAutoplay?: number
  // should be unique if there are multiple swiper in a page that not related
  identifierPrefixCls?: string
  initialSlideIndex?: number
  isFreeMode?: boolean
  positionButton?: {
    x?: string | number
    y?: string | number
  }
  spaceBetween?: number
}

type SwipePaginationProps = {
  dynamicMainBullets?: number
  prefixCls: string
  paginationStyle?: CSSProperties
}

const SwipePagination = ({
  dynamicMainBullets,
  prefixCls,
  paginationStyle,
}: SwipePaginationProps) => {
  return (
    <div
      className={`${prefixCls}-${!dynamicMainBullets ? PAGINATION_CLS : PAGINATION_DYNAMIC_CLS}`}
      style={paginationStyle}
    />
  )
}

function SwiperSlides({
  slidesPerView = 4,
  items,
  wrapperCls = '',
  style,
  pagination = false,
  paginationStyle,
  btnControlSize = 'md',
  dynamicMainBullets,
  showNavigationButtons = true,
  isLoop = true,
  autoplay = false,
  delayAutoplay = 0,
  identifierPrefixCls = 'swiper-slides',
  paginationPosition = 'outer',
  positionButton = {
    x: -10,
    y: '50%',
  },
  initialSlideIndex = 0,
  isFreeMode = false,
  spaceBetween = 20,
}: SwiperSlidesProps) {
  const duplicatedItems = useMemo(() => {
    return showNavigationButtons && items.length > slidesPerView
      ? [...items, ...items]
      : items
  }, [items, slidesPerView, showNavigationButtons])

  return (
    <div
      className={`sp3-swiper-container swiper-slides-container sp3-swiper-pagination-${paginationPosition} ${wrapperCls}`}
      style={style}
    >
      <Swiper
        modules={[Autoplay, Navigation, Pagination, FreeMode]}
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        freeMode={isFreeMode}
        autoplay={
          autoplay
            ? {
                delay: delayAutoplay,
                disableOnInteraction: false,
              }
            : false
        }
        navigation={{
          prevEl: `.${identifierPrefixCls}-${DEFAULT_PREV_CLS}`,
          nextEl: `.${identifierPrefixCls}-${DEFAULT_NEXT_CLS}`,
        }}
        pagination={{
          el: `.${identifierPrefixCls}-${!dynamicMainBullets ? PAGINATION_CLS : PAGINATION_DYNAMIC_CLS}`,
          clickable: true,
          enabled: pagination,
          type: 'bullets',
          dynamicBullets: true,
          dynamicMainBullets: dynamicMainBullets ?? items.length,
        }}
        initialSlide={initialSlideIndex}
        loop={isLoop}
      >
        {duplicatedItems.map((item, index) => (
          <SwiperSlide key={index}>{item}</SwiperSlide>
        ))}
      </Swiper>

      {showNavigationButtons && (
        <Fragment>
          <ButtonCircle
            wrapperCls={`${identifierPrefixCls}-${DEFAULT_PREV_CLS} btn-navigate-swiper--${btnControlSize}`}
            style={{
              left: positionButton.x || -10,
              top: positionButton.y || '50%',
            }}
            icon={<ArrowLeftOutlined />}
          />

          <ButtonCircle
            wrapperCls={`${identifierPrefixCls}-${DEFAULT_NEXT_CLS} btn-navigate-swiper--${btnControlSize}`}
            style={{
              right: positionButton.x || -10,
              top: positionButton.y || '50%',
            }}
            icon={<ArrowRightOutlined />}
          />
        </Fragment>
      )}

      {pagination && (
        <SwipePagination
          dynamicMainBullets={dynamicMainBullets}
          prefixCls={identifierPrefixCls}
          paginationStyle={paginationStyle}
        />
      )}
    </div>
  )
}

export default SwiperSlides
