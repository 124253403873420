import { CSSProperties, useMemo } from 'react'

import './index.scss'

type AmountTagProps = {
  tagName: string
  position: 'relative' | 'absolute'
  offset?: [number | string, number | string]
  className?: string
  style?: CSSProperties
}

function AmountTag({
  tagName,
  className = '',
  position = 'relative',
  offset,
  style,
}: AmountTagProps) {
  const mergedStyle = useMemo<CSSProperties>(() => {
    if (!offset) {
      return { ...style }
    }

    const offsetStyle: CSSProperties = {}
    offsetStyle.right = offset[0]
    offsetStyle.top = offset[1]

    return { ...offsetStyle, ...style }
  }, [offset, style])

  return (
    <span className={`amount-tag ${position} ${className}`} style={mergedStyle}>
      {tagName}
    </span>
  )
}

export default AmountTag
